@import "~ng-pick-datetime/assets/style/picker.min.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-horizontal-stepper-header-container  {
  display: none !important;
}

.agm-map-container-inner {
  margin-top: 20px;
}

.mat-horizontal-content-container {
  padding: initial !important;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  background: transparent;
}

button.gm-control-active.gm-fullscreen-control {
  display: none;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  display: none;
}

.agm-map-container-inner.sebm-google-map-container-inner {
  opacity: 0.9;
}

.agm-map-container-inner {
  margin: 0;
}

.form-control {
  border-radius: 0;
  border-color: transparent;
}


.mat-form-field-flex {
  background-color: #fff;
}

.site-branding img {
  border-radius: 20px;
}

span.mat-radio-label-content {
  color: #000 !important;
}